<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.$router.replace("/product/edit");
  },
};
</script>

<style>
</style>